import React, { useEffect, useState } from "react";
import { Sheet, SheetRef } from "react-modal-sheet";

import { SimplifiedCenter } from "@/types";

interface SavedCenter extends SimplifiedCenter {
  isSaved: boolean;
  // 각 유저별로 다른 값을 가져야 함
  visitCount: number;
}

// mock data
const savedCenters: SavedCenter[] = [
  {
    id: 1,
    name: "클라이밍 센터 1",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 12,
    isSaved: true,
  },
  {
    id: 2,
    name: "클라이밍 센터 2",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 12,
    isSaved: true,
  },
  {
    id: 3,
    name: "클라이밍 센터 3",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 12,
    isSaved: false,
  },
  {
    id: 4,
    name: "클라이밍 센터 4",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 122,
    isSaved: false,
  },
  {
    id: 5,
    name: "클라이밍 센터 5",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 132,
    isSaved: false,
  },
  {
    id: 6,
    name: "클라이밍 센터 6",
    address: "서울특별시 강남구 테헤란로 123",
    visitCount: 412,
    isSaved: false,
  },
];

// mock nearby centers
const nearbyCenters: SimplifiedCenter[] = [
  {
    id: 4,
    name: "내 주변 센터 1",
    address: "서울특별시 강남구 역삼동 456",
  },
  {
    id: 5,
    name: "내 주변 센터 2",
    address: "서울특별시 강남구 논현동 789",
  },
  {
    id: 6,
    name: "내 주변 센터 3",
    address: "서울특별시 강남구 삼성동 123",
  },
];

const renderSavedCenters = (centers: SavedCenter[]) => {
  return centers.map((center, index) => (
    <div
      key={index}
      className="bg-gray-700 p-4 rounded-lg mb-2 flex justify-between items-center"
    >
      <div>
        <h3 className="text-white font-bold text-xl">{center.name}</h3>

        <p className=" text-sm text-[rgb(255,255,255)]/[0.5]">
          {center.address}
        </p>

        <span className="text-gray-300 text-sm mt-2 inline-block py-[6px] px-[12px] border border-[rgb(255,255,255)]/[0.5] rounded-2xl">
          12번 방문
        </span>
      </div>

      <div>
        <img
          className="h-8 w-8"
          src="/icons/maps/filledStar.png"
          alt="채워진 별"
        />
      </div>
    </div>
  ));
};

const renderNearbyCenters = (centers: SimplifiedCenter[]) => {
  return centers.map((center, index) => (
    <div
      key={index}
      className="bg-gray-700 p-4 rounded-lg mb-2 flex justify-between items-center"
    >
      <div>
        <h3 className="text-white font-bold text-xl">{center.name}</h3>

        <p className=" text-sm text-[rgb(255,255,255)]/[0.5]">
          {center.address}
        </p>

        {/* FIXME */}
        {/* <span className="text-gray-300 text-sm mt-2 inline-block">
          영업중 XX
        </span> */}
      </div>
    </div>
  ));
};

interface BottomSheetComponentProps {
  isSheetOpen?: boolean;
  sheetRef: React.RefObject<SheetRef>;
  onMinimize: () => void;
  snapPoints: number[];
  isSheetFullyOpen: boolean;
  moveToUserLocation: () => void;
  handleSheetOpen: (index: number) => void;
}

export default function BottomSheetComponent({
  isSheetOpen = true,
  sheetRef,
  onMinimize,
  snapPoints,
  isSheetFullyOpen,
  moveToUserLocation,
  handleSheetOpen,
}: BottomSheetComponentProps) {
  const [activeTab, setActiveTab] = useState("saved");

  // 바텀시트가 열릴 때 body 스크롤 막기
  useEffect(() => {
    if (isSheetOpen) {
      document.body.style.overflow = "hidden"; // 페이지 스크롤 막기
    } else {
      document.body.style.overflow = ""; // 페이지 스크롤 다시 허용
    }

    // Clean up 함수로 시트가 닫힐 때 스크롤 허용
    return () => {
      document.body.style.overflow = "";
    };
  }, [isSheetOpen]);

  const renderCenters = () => {
    if (activeTab === "saved") {
      return renderSavedCenters(savedCenters);
    } else if (activeTab === "nearby") {
      return renderNearbyCenters(nearbyCenters);
    }
  };

  return (
    <div style={{ zIndex: 1000, position: "fixed", bottom: 0, width: "100%" }}>
      <Sheet
        ref={sheetRef} // 시트에 대한 참조를 설정
        isOpen={isSheetOpen}
        onClose={onMinimize}
        snapPoints={snapPoints} // 동적으로 전달받은 snapPoints 사용
        onSnap={handleSheetOpen}
        initialSnap={1}
        disableScrollLocking={!isSheetFullyOpen}
      >
        <Sheet.Container
          style={{
            backgroundColor: "#2B2E39",
            height: "calc(100vh - 150px)",
            overflow: "hidden",
          }}
        >
          <Sheet.Header />
          <Sheet.Content disableDrag={isSheetFullyOpen}>
            <div className="p-4 h-full">
              {/* Segmented Control */}
              <div className="flex mb-4">
                <button
                  onClick={() => setActiveTab("saved")}
                  className={`flex-1 py-2 rounded-l-md ${
                    activeTab === "saved"
                      ? "bg-[#D7E950] text-[#070709]"
                      : "bg-[#444755] text-[#7B7F8E]"
                  }
                  text-center text-sm not-italic font-medium leading-5
                  `}
                >
                  저장된 센터
                </button>
                <button
                  onClick={() => setActiveTab("nearby")}
                  className={`flex-1 py-2 rounded-r-md ${
                    activeTab === "nearby"
                      ? "bg-[#D7E950] text-[#070709]"
                      : "bg-[#444755] text-[#7B7F8E]"
                  }
                  text-center text-sm not-italic font-medium leading-5
                  `}
                >
                  내 주변 센터
                </button>
              </div>

              {/* Centers List */}
              <Sheet.Scroller
                style={{
                  height: "calc(100vh - 200px)",
                  overflowY: "auto",
                  WebkitOverflowScrolling: "touch",
                }}
                onTouchStart={(e) => {
                  e.stopPropagation(); // 터치 이벤트가 바텀시트 드래그로 전파되지 않도록 방지
                }}
                onTouchMove={(e) => {
                  e.stopPropagation();
                }}
                draggableAt={"both"}
              >
                {renderCenters()}
              </Sheet.Scroller>
            </div>
          </Sheet.Content>

          {!isSheetFullyOpen ? (
            <button
              onClick={moveToUserLocation}
              // 드래그 중일 때 버튼 숨김
              className={`absolute top-[-64px] right-4 bg-white rounded-full shadow-md z-10 h-12 w-12`}
            >
              {/* 내 위치로 이동 */}
              <img
                src="/icons/maps/moveMyLocation@2x.png"
                alt="내 위치로 이동"
              />
            </button>
          ) : null}
        </Sheet.Container>

        {/* Sheet.Backdrop은 제거, 지도 터치 가능 */}
      </Sheet>
    </div>
  );
}
