"use client";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useEffect, useRef } from "react";
import { useRouter } from "next/navigation";

import { ClimbingCenter } from "@/types";

interface MapComponentProps {
  centers: ClimbingCenter[];
  naverMapsLoaded: boolean;
  onMapClick: () => void;
  initialCenter: {
    lat: number;
    lng: number;
  };
  initialZoom: number;
  onMapMove: (center: naver.maps.LatLng, zoomLevel: number) => void;
}

export interface MapComponentRef {
  moveToUserLocation: () => void;
  moveToLocation: (lat: number, lng: number) => void;
}

const MapComponent = forwardRef<MapComponentRef, MapComponentProps>(
  (
    {
      centers,
      naverMapsLoaded,
      onMapClick,
      initialCenter,
      initialZoom,
      onMapMove,
    },
    ref
  ) => {
    const mapElement = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<naver.maps.Map | null>(null);
    const [userLocationMarker, setUserLocationMarker] =
      useState<naver.maps.Marker | null>(null);
    const [currentCenter, setCurrentCenter] =
      useState<naver.maps.LatLng | null>(null);
    const router = useRouter();

    useImperativeHandle(ref, () => ({
      moveToUserLocation: () => {
        if (userLocationMarker && mapInstance.current) {
          console.log(
            "Moving to user location:",
            userLocationMarker.getPosition()
          );
          const userPosition = userLocationMarker.getPosition();
          mapInstance.current.setCenter(userPosition);
          setCurrentCenter(userPosition); // 사용자 위치로 중심 설정
        } else {
          console.error("User location or map instance is not available");
          alert(
            "내 위치를 가져올 수 없습니다. 위치 정보가 설정되었는지 확인하세요."
          );
        }
      },
      moveToLocation: (lat: number, lng: number) => {
        if (mapInstance.current) {
          mapInstance.current.setCenter(new naver.maps.LatLng(lat, lng)); // 선택된 위치로 지도 이동
        }
      },
    }));

    useEffect(() => {
      if (mapElement.current && naverMapsLoaded) {
        const { naver } = window;

        const mapOptions: {
          center: naver.maps.LatLng;
          zoom: number;
          zoomControl: boolean;
        } = {
          center: new naver.maps.LatLng(initialCenter.lat, initialCenter.lng),
          zoom: initialZoom,
          zoomControl: false,
        };

        const map = new naver.maps.Map(mapElement.current, mapOptions);
        mapInstance.current = map;

        // 지도 클릭 이벤트
        naver.maps.Event.addListener(map, "click", () => {
          if (onMapClick) {
            onMapClick(); // 지도를 클릭할 때 바텀시트를 최소화
          }
        });

        // Map idle event to track center and zoom changes
        naver.maps.Event.addListener(map, "idle", () => {
          const newCenter = map.getCenter();
          const newZoom = map.getZoom();
          onMapMove(newCenter, newZoom); // Call parent when map is moved
        });

        // 클라이밍 센터 마커 추가
        centers.forEach((center) => {
          const marker = new naver.maps.Marker({
            position: new naver.maps.LatLng(center.latitude, center.longitude),
            map: map,
            title: center.name,
          });

          naver.maps.Event.addListener(marker, "click", () => {
            router.push(`/center/${center.id}`);
          });
        });
        // 웹뷰로부터 메시지를 수신하여 위치 데이터를 처리
        document.addEventListener("message", (event: Event) => {
          try {
            // document type error를 해결하기 위해 event를 MessageEvent로 변환
            const message = JSON.parse((event as MessageEvent).data);
            console.log("Received message in WebView:", message);

            if (message.latitude && message.longitude) {
              const userLocation = new naver.maps.LatLng(
                message.latitude,
                message.longitude
              );

              if (!userLocationMarker) {
                const markerOptions: naver.maps.MarkerOptions = {
                  position: userLocation,
                  map: map,
                  title: "내 위치",
                  icon: {
                    url: "/icons/maps/myLocationPoint@2x.png",
                    size: new naver.maps.Size(48, 48),
                    scaledSize: new naver.maps.Size(24, 24),
                    anchor: new naver.maps.Point(12, 24),
                  },
                };

                const marker = new naver.maps.Marker(markerOptions);
                setUserLocationMarker(marker);
              } else {
                userLocationMarker.setPosition(userLocation);
              }

              setCurrentCenter(userLocation); // 사용자 위치로 중심 설정
            }
          } catch (e) {
            console.error("Error processing message:", e);
          }
        });
      }
    }, [naverMapsLoaded, centers, onMapClick, router, userLocationMarker]);

    return (
      <div className="relative w-full h-full">
        <div ref={mapElement} className="w-full h-full"></div>
      </div>
    );
  }
);

MapComponent.displayName = "MapComponent";

export default MapComponent;
