import { useState, useEffect, useRef } from "react";
import { Sheet, SheetRef } from "react-modal-sheet";

import CenterSearchBar from "@/components/CenterSearchBar";
import { climbingCenters } from "@/data/climbing-centers"; // 예시 데이터
import { colors } from "@/Shared/colors";
import { SimplifiedCenter } from "@/types";

import "./SearchModal.css";

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCenterSelect: (centerId: number) => void;
}

export default function SearchModal({
  isOpen,
  onClose,
  onCenterSelect,
}: SearchModalProps) {
  const sheetRef = useRef<SheetRef>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCenters, setFilteredCenters] = useState<SimplifiedCenter[]>(
    []
  );

  useEffect(() => {
    if (searchTerm) {
      const filtered = climbingCenters.filter((center) =>
        center.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCenters(filtered);
    } else {
      setFilteredCenters([]);
    }
  }, [searchTerm]);

  return (
    <div
      style={{
        zIndex: 1000,
        width: "100%",
      }}
    >
      <Sheet ref={sheetRef} isOpen={isOpen} onClose={onClose}>
        <Sheet.Container
          style={{
            backgroundColor: colors.bgBlack,
            position: "fixed",
            top: 0,
            transition: "transform 0.001s ease !important", // Smooth fade and slide animation
          }}
          className={`custom-sheet-container`} // 모달이 열리면 open 클래스 추가
        >
          <Sheet.Content>
            <div className="p-4 relative">
              <div className="flex items-center gap-4 mb-4">
                {/* 닫기 버튼 */}
                <button
                  onClick={onClose}
                  className="bg-white rounded-full flex items-center justify-center"
                >
                  {/* X 아이콘 */}
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src="/icons/close.svg"
                    alt="닫기"
                    className="w-12 h-12"
                  />
                </button>

                {/* 검색바 */}
                <div className="flex-grow">
                  <CenterSearchBar
                    searchTerm={searchTerm}
                    onSearchTermChange={setSearchTerm}
                  />
                </div>
              </div>

              {/* 검색 결과 목록 */}
              {filteredCenters.length > 0 && (
                <>
                  <div
                    className={`flex justify-between items-center p-4 text-sm text-textWhite border border-width-2 border-borderGray03 rounded-2xl`}
                  >
                    <div>찾는 장소가 없으신가요?</div>
                    <button
                      className={`text-sm font-medium text-primary py-2 px-4`}
                    >
                      등록 요청
                    </button>
                  </div>

                  <ul className={`w-full mt-2 text-textWhite rounded-lg z-50`}>
                    {filteredCenters.map((center) => (
                      <li
                        key={center.id}
                        className="py-[12px] cursor-pointer"
                        onClick={() => {
                          onCenterSelect(center.id); // 센터 선택 시 콜백 호출
                          onClose(); // 모달 닫기
                        }}
                      >
                        <div className="text-md font-semibold">
                          {center.name}
                        </div>
                        <div className={`text-xs text-text01`}>
                          {center.address}
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
}
