"use client";
import Script from "next/script";
import { useEffect, useRef, useState } from "react";
import { SheetRef } from "react-modal-sheet";

import { supabase } from "@/lib/supabaseClient";
import CenterSearchBar from "@/components/CenterSearchBar";
import MapComponent, { MapComponentRef } from "@/components/MapComponent";
import BottomSheetComponent from "@/components/BottomSheetComponent";
import { ClimbingCenter } from "@/types";
import { naverMapsAPIUrl } from "@/config";
import SearchModal from "@/components/search/SearchModal";

export default function Home() {
  const [naverMapsLoaded, setNaverMapsLoaded] = useState(false);
  const [supabaseCenters, setSupabaseCenters] = useState<ClimbingCenter[]>([]);
  const [isSheetFullyOpen, setIsSheetFullyOpen] = useState(false); // 바텀시트가 최대로 열렸는지 여부

  const sheetRef = useRef<SheetRef>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapComponentRef>(null);

  // 바텀시트 최대 높이인지 추적
  const handleSheetOpen = (index: number) => {
    setIsSheetFullyOpen(index === 0);
  };

  // 지도를 클릭할 때 바텀시트를 최소화
  const onMinimizeBottomSheet = () => {
    if (sheetRef.current) {
      sheetRef.current.snapTo(3); // 최소 스냅 포인트로 이동 (닫히는 대신 최소화)
      setIsSheetFullyOpen(false); // 최소화 시 바텀시트가 최대로 열리지 않은 상태로 설정
    }
  };

  // SearchBar의 높이를 바탕으로 바텀시트의 최대 스냅 포인트 설정
  const calculateSnapPoints = () => {
    const viewportHeight = window.innerHeight;
    const searchBarHeight = searchBarRef.current?.offsetHeight || 0;
    const maxSheetHeight = viewportHeight - searchBarHeight - 32; // SearchBar 아래 16px까지 열리도록

    return [maxSheetHeight, 250, 40];
  };

  // 네이버 지도 로딩 상태
  useEffect(() => {
    const loadNaverMaps = () => {
      if (window.naver && window.naver.maps) {
        setNaverMapsLoaded(true);
      } else {
        const intervalId = setInterval(() => {
          if (window.naver && window.naver.maps) {
            setNaverMapsLoaded(true);
            clearInterval(intervalId);
          }
        }, 100);
      }
    };

    loadNaverMaps();
  }, []);

  useEffect(() => {
    const fetchCenters = async () => {
      const { data, error } = await supabase.from("ClimbingCenter").select("*");

      if (error) {
        console.error("Error fetching centers:", error);
      } else {
        setSupabaseCenters(data || []);
      }
    };

    fetchCenters();
  }, []);

  // 내 위치로 이동
  const moveToUserLocation = () => {
    if (mapRef.current) {
      mapRef.current.moveToUserLocation();
    }
  };

  // 지도의 상태 유지
  const [mapCenter, setMapCenter] = useState({
    lat: 37.5665,
    lng: 126.978,
  }); // Initial center (Seoul)
  const [zoom, setZoom] = useState(16); // Initial zoom level
  const handleMapMove = (center: naver.maps.LatLng, zoomLevel: number) => {
    setMapCenter({ lat: center.lat(), lng: center.lng() });
    setZoom(zoomLevel);
  };

  // 선택된 센터의 좌표로 이동하는 함수
  const handleCenterSelect = (centerId: number) => {
    // 선택된 ID에 해당하는 센터를 supabaseCenters에서 찾음
    const selectedCenter = supabaseCenters.find(
      (center) => center.id === centerId
    );
    if (selectedCenter && mapRef.current) {
      // 지도에서 해당 위치로 이동
      mapRef.current.moveToLocation(
        selectedCenter.latitude,
        selectedCenter.longitude
      );
    }
  };

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // 검색 모달 상태
  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };
  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  return (
    <>
      <Script
        type="text/javascript"
        src={naverMapsAPIUrl}
        strategy="afterInteractive"
      />
      <div className="relative w-full h-screen text-black">
        <div className="z-10 absolute top-4 left-4 w-[calc(100vw-32px)] max-w-screen mx-auto">
          <CenterSearchBar
            searchBarRef={searchBarRef}
            searchTerm={""}
            onSearchTermChange={() => null} // 검색어 입력 기능은 사용하지 않음
            onClick={openSearchModal}
            disableInput
          />
        </div>

        {/* 네이버 지도 API가 로드된 후에만 MapComponent를 렌더링 */}
        {naverMapsLoaded && (
          <MapComponent
            ref={mapRef}
            centers={supabaseCenters}
            naverMapsLoaded={naverMapsLoaded}
            onMapClick={onMinimizeBottomSheet}
            initialCenter={mapCenter}
            initialZoom={zoom}
            onMapMove={handleMapMove}
          />
        )}

        <BottomSheetComponent
          sheetRef={sheetRef}
          onMinimize={onMinimizeBottomSheet}
          snapPoints={calculateSnapPoints()} // 동적으로 계산된 snapPoints 전달
          isSheetFullyOpen={isSheetFullyOpen}
          moveToUserLocation={moveToUserLocation}
          handleSheetOpen={handleSheetOpen}
        />

        {/* 검색 모달 */}
        <SearchModal
          isOpen={isSearchModalOpen}
          onClose={closeSearchModal}
          onCenterSelect={handleCenterSelect}
        />
      </div>
    </>
  );
}
