import React from "react";

import { BsSearch } from "react-icons/bs";

interface CenterSearchBarProps {
  searchBarRef?: React.RefObject<HTMLDivElement>;
  searchTerm: string;
  onSearchTermChange: (term: string) => void;
  onSearchSubmit?: () => void;
  onClick?: () => void;
  // e.g. 센터 찾기 페이지에서 입력 막음
  disableInput?: boolean;
}

export default function CenterSearchBar({
  searchBarRef,
  searchTerm,
  onSearchTermChange,
  onSearchSubmit,
  onClick,
  disableInput = false,
}: CenterSearchBarProps) {
  return (
    <div
      ref={searchBarRef}
      className="z-11 w-full flex items-center bg-white py-[14px] px-4 shadow-mdh-12 rounded-[24px]"
      onClick={onClick}
    >
      <input
        type="text"
        value={searchTerm}
        placeholder="센터명 및 위치 검색"
        disabled={disableInput}
        className={`flex-grow text-textBlack bg-white outline-none h-full rounded-[24px] pl-4 placeholder:text-[#999CAA] ${
          onClick ? "pointer-events-none" : ""
        }`}
        onChange={(e) => onSearchTermChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onSearchSubmit) {
            onSearchSubmit();
          }
        }}
      />
      <BsSearch
        className="ml-2 h-5 w-5 text-[#B6B6B6] cursor-pointer"
        onClick={onSearchSubmit}
      />
    </div>
  );
}
