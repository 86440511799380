const config = {
  test: {
    host: "http://localhost:3000",
    port: 3000,
  },
  production: {
    host: "http://localhost:3000",
    port: 3000,
  },
  development: {
    host: "http://localhost:3000",
    port: 3000,
  },
};

export default config[process.env.NODE_ENV];

export const naverMapsAPIUrl = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.NEXT_PUBLIC_NAVER_MAP_CLIENT_ID}`;
