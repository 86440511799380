export const climbingCenters = [
  {
    id: 1,
    name: "더클라임 클라이밍 짐앤샵 일산점",
    address: "경기 고양시 일산동구 중앙로 1160 5층 더클라임",
  },
  {
    id: 2,
    name: "더클라임 클라이밍 짐앤샵 마곡점",
    address: "서울 강서구 마곡동로 62 마곡사이언스타워 7층",
  },
  {
    id: 3,
    name: "더클라임 클라이밍 짐앤샵 서울대점",
    address: "서울 관악구 남부순환로 1801 지하1층",
  },
  {
    id: 4,
    name: "더클라임 클라이밍 짐앤샵 양재점",
    address: "서울 강남구 남부순환로 2615 지하1층",
  },
  {
    id: 5,
    name: "더클라임 클라이밍 짐앤샵 강남점",
    address: "서울 강남구 테헤란로8길 21 화인강남빌딩 B1층",
  },
  {
    id: 6,
    name: "더클라임 클라이밍 짐앤샵 연남점",
    address: "서울 마포구 양화로 186 3층",
  },
  {
    id: 7,
    name: "더클라임 클라이밍 짐앤샵 사당점",
    address: "서울 관악구 과천대로 939 지층 B201호",
  },
  {
    id: 8,
    name: "더클라임 클라이밍 짐앤샵 신림점",
    address: "서울 관악구 신원로 35 삼모더프라임타워 5층",
  },
  {
    id: 9,
    name: "더클라임 논현점",
    address: "서울 서초구 강남대로 519 지하1층",
  },
  {
    id: 10,
    name: "더클라임 신사점",
    address: "서울 강남구 압구정로2길 6 지하2층",
  },
  {
    id: 11,
    name: "더클라임 문래점",
    address: "서울 영등포구 당산로 63",
  },
  {
    id: 12,
    name: "더클라임 B 홍대점",
    address: "서울 마포구 양화로 125 2층",
  },
  {
    id: 13,
    name: "서울숲클라이밍 뚝섬점",
    address: "서울 성동구 성수일로 19 유한타워 B2층",
  },
  {
    id: 14,
    name: "서울숲클라이밍 영등포점",
    address: "서울 영등포구 문래로 164 B동 1층",
  },
  {
    id: 15,
    name: "서울숲클라이밍 잠실점",
    address: "서울 송파구 백제고분로7길 49 지하1층",
  },
  {
    id: 16,
    name: "서울숲클라이밍 종로점",
    address: "서울 종로구 수표로 96 지하1층",
  },
  {
    id: 17,
    name: "서울숲클라이밍 구로점",
    address: "서울 구로구 디지털로 300 지하1층",
  },
  {
    id: 18,
    name: "클라이밍파크 종로점",
    address: "서울 종로구 종로 199 한일빌딩 지하2층",
  },
  {
    id: 19,
    name: "클라이밍파크 한티점",
    address: "서울 강남구 선릉로 324 SH타워 지하3층",
  },
  {
    id: 20,
    name: "클라이밍파크 신논현점",
    address: "서울 강남구 강남대로 468 지하3층",
  },
  {
    id: 21,
    name: "클라이밍파크 성수점",
    address: "서울 성동구 연무장13길 7 매니아빌딩",
  },
  {
    id: 22,
    name: "클라이밍파크 강남점",
    address: "서울 강남구 강남대로 364 지하1층",
  },
  {
    id: 23,
    name: "PEAKERS 클라이밍 종로",
    address: "서울 종로구 돈화문로5가길 1 지하4층",
  },
  {
    id: 24,
    name: "PEAKERS 클라이밍 구로",
    address: "서울 구로구 구로중앙로 152 NC신구로점 6층",
  },
  {
    id: 25,
    name: "PEAKERS 클라이밍 신촌",
    address: "서울 서대문구 신촌로 129 아트레온 11층",
  },
  {
    id: 26,
    name: "클라임 어스 모란점",
    address: "경기 성남시 중원구 성남대로 1126 6층",
  },
  {
    id: 27,
    name: "클라임 어스 미사점",
    address: "경기 하남시 미사강변동로 81 13층",
  },
  {
    id: 28,
    name: "클라임 어스 장한평점",
    address: "서울 동대문구 장한로2길 63 호종빌딩 2층",
  },
  {
    id: 29,
    name: "알레클라이밍 혜화점",
    address: "서울 종로구 창경궁로34길 18-5 토가빌딩 B2층",
  },
  {
    id: 30,
    name: "알레클라이밍 강동점",
    address: "서울 강동구 천호대로 177길 39 지하2층",
  },
  {
    id: 31,
    name: "알레클라이밍 영등포점",
    address: "서울 영등포구 영등포로 33길 14",
  },
  {
    id: 32,
    name: "손상원 클라이밍짐 강남역점",
    address: "서울 서초구 강남대로 331 지하1층",
  },
  {
    id: 33,
    name: "손상원 클라이밍짐 판교점",
    address: "경기 성남시 분당구 대왕판교로 670 유스페이스2 B동 지하 1층",
  },
  {
    id: 34,
    name: "손상원 클라이밍짐 을지로점",
    address: "서울 중구 남대문로 125 지하1층",
  },
  {
    id: 35,
    name: "클라임바운스 수원점",
    address: "경기 수원시 영통구 영통동 981-1 판타지움 3층",
  },
  {
    id: 36,
    name: "클라임바운스 이천점",
    address: "경기 이천시 부발읍 경충대로 2050번길 59-76 다온빌딩 지하1층",
  },
  {
    id: 37,
    name: "클라임바운스 창전점",
    address: "경기 이천시 이섭대천로 1229 9층 901, 903호",
  },
  {
    id: 38,
    name: "디스커버리클라이밍 클라임스퀘어 ICN",
    address: "인천 서구 완정로 70",
  },
  {
    id: 39,
    name: "디스커버리클라이밍 클라임스퀘어 송도점",
    address: "인천 연수구 송도과학로16번길 33-4 트리플스트리트 D동 211a~c호",
  },
  {
    id: 40,
    name: "디스커버리클라이밍 클라임스퀘어 청라점",
    address: "인천 서구 청라라임로 71 진영메디피아 1002호",
  },
  {
    id: 41,
    name: "을지로 담장",
    address: "서울 중구 마른내로 63-3 2F",
  },
  {
    id: 42,
    name: "신촌 담장",
    address: "서울 서대문구 신촌역로 10 5층",
  },
  {
    id: 43,
    name: "서울볼더스 선유",
    address: "서울 영등포구 양평로 28마길 7 3층",
  },
  {
    id: 44,
    name: "서울볼더스 컴퍼니",
    address: "서울 양천구 신목로 53 2층",
  },
  {
    id: 45,
    name: "더플라스틱 클라이밍 문래점",
    address: "서울 영등포구 도림로 423 1층",
  },
  {
    id: 46,
    name: "더플라스틱 클라이밍 염창점",
    address: "서울 강서구 공항대로 81길 27 1층",
  },
  {
    id: 47,
    name: "락트리클라이밍 강남",
    address: "서울 강남구 강남대로 118길 12 B2층",
  },
  {
    id: 48,
    name: "락트리클라이밍 분당",
    address: "경기 성남시 분당구 황새울로 224 청구블루빌 지하1층",
  },
  {
    id: 49,
    name: "훅클라이밍 왕십리점",
    address: "서울 성동구 고산자로6길 40 2층 훅클라이밍",
  },
  {
    id: 50,
    name: "훅클라이밍 성수점",
    address: "서울 성동구 성수일로12길 34 3층",
  },
  {
    id: 51,
    name: "비블럭 클라이밍 송도점",
    address: "인천 연수구 송도과학로 16번길 13-39 107호",
  },
  {
    id: 52,
    name: "비블럭 클라이밍 영종하늘도시점",
    address: "인천 중구 하늘중앙로 195번길 18 6층 601-604호",
  },
  {
    id: 53,
    name: "클라임잇 클라이밍 구로점",
    address: "서울 구로구 구로중앙로 215 지층",
  },
  {
    id: 54,
    name: "클라임잇 클라이밍 목감점",
    address: "경기 시흥시 배미골길 24 1층",
  },
  {
    id: 55,
    name: "코알라클라이밍 상암",
    address: "서울 마포구 월드컵북로 396 누리꿈스퀘어 디지털파빌리온 B1040호",
  },
  // 추가할 센터들…
];
